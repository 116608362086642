"use client";
import "./App.css";
import React, { useRef, useState, useEffect } from "react";
import { useScroll, useTransform, motion } from "framer-motion";

const App = ({
  titleComponent,
  children,
}) => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
  });
  const [isMobile, setIsMobile] = useState(false);
  const [gekopieerd, setGekopieerd] = useState(false);
  const [switchState, setSwitchState] = useState("Huidig-");
  const [showVideo, setShowVideo] = useState(false);

  const videoId = 'UqYchm53kd4';
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  const embedUrl = `https://www.youtube.com/embed/${videoId}?enablejsapi=1`;

  const handleThumbnailClick = () => {
    setShowVideo(true);
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const scaleDimensions = () => {
    return isMobile ? [0.7, 0.9] : [1.05, 1];
  };

  const rotate = useTransform(scrollYProgress, [0, 1], [20, 0]);
  const scale = useTransform(scrollYProgress, [0, 1], scaleDimensions());
  const translate = useTransform(scrollYProgress, [0, 1], [0, -100]);

  return (
    <div className="h-full w-full pt-12 lg:pt-20 bg-[#eeeeef] overflow-x-hidden">
      <div className="flex justify-center cursor-default pointer-events-none">
        <img src="/images/removed.png" alt="Lewan Paalvast" className="inline hidden w-[300px] lg:w-[500px] -mt-10 lg:-mt-20 mb-4 aspect-square" />
      </div>
      <span className="ayer flex justify-center text-center text-[48px] xl:text-[111px] leading-none text-[#161618]">Lewan is een webdesigner & <br></br>Computer Science student @ TUDelft</span>
      <div className="flex justify-center items-center">
        <div className="z-[9000000] gap-2 cursor-grab flex justify-center items-center relative max-w-fit"
          onClick={() => {
            navigator.clipboard.writeText("paalvast.lewan@gmail.com");
            setGekopieerd(true);
            setTimeout(() => setGekopieerd(false), 2000);
          }}>
          <span className="relative flex h-2 w-2">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-500 opacity-75"></span>
            <span className="relative inline-flex h-2 w-2 rounded-full bg-green-500"></span>
          </span>
          <span className="graphik underline">
            {gekopieerd ? 'Gekopieerd. Tot zo!' : 'Email kopiëren'}
          </span>
        </div>
      </div>

      <div
        className="z-[10] h-[60rem] md:h-[80rem] flex items-center justify-center relative p-2 md:p-20 pt-0 -my-56 xl:-my-28"
        ref={containerRef}
      >
        <div
          className="py-10 md:py-40 w-full relative"
          style={{
            perspective: "900px",
          }}
        >
          <Header translate={translate} titleComponent={titleComponent} />
          <Card rotate={rotate} translate={translate} scale={scale}>
            {children}
          </Card>
        </div>
      </div>
      <div className="flex justify-center graphik mb-4 xl:mb-16 -mt-64 xl:-mt-52">
        <p className="z-[9000000000] w-[800px] text-center p-4 pt-0 xl:p-0">
          <a href="https://eindexamen.space" className="cursor-alias underline graphikMedium" target="__blank__">Eindexamen.space</a> is gebouwd om leerlingen te helpen zo snel mogelijk examenopgaves bij specifieke stof te vinden. Daarnaast kunnen leerlingen zien welke opgaves ze al hebben gemaakt, hun studiesessies en vooruitgang door de tijd heen terugzien.
        </p>
      </div>
      <div className="flex justify-center text-center bg-[#232326] text-[#eeeeef] border-y-8 border-[#161618] pt-4 lg:pb-6 xl:py-8 w-full">
        <div className="mb-2">
          <div className="ayer text-[48px] xl:text-[69px] z-[20] mb-4 lg:mb-14">
            {["Huidig-", "eerste"].map((status, index) => (
              <React.Fragment key={status}>
                <span
                  className={`cursor-pointer flex-justify-center ${switchState == status ? "underline" : ""}`}
                  onClick={() => setSwitchState(status)}
                >
                  {status}
                </span>
                {index === 0 ? <span className="mx-2">vs</span> : <span className="mx-2">project</span>}
              </React.Fragment>
            ))}
          </div>

          <div id="cover" className="flex justify-center lg:h-[600px] lg:my-0 w-full aspect-square p-0 lg:w-[900px]">
            {switchState === "Huidig-" ? (

              <div className="w-full h-full px-8 lg:-mt-4  flex">
                <div className="flex flex-col sm:flex-row w-full pb-5 sm:p-0 sm:h-full">
                  <div className="w-full sm:w-[calc(50% - 10px)] overflow-hidden rounded-lg">
                    <div id="after" className="w-full h-full overflow-y-scroll">
                      <img src="/images/maycroftafter.png" alt="cover" className="w-full object-cover object-top" />
                    </div>
                  </div>
                </div>

              </div>
            ) : (
              <div className="relative w-[100vw] px-[5vw] lg:px-0 md:w-full h-full overflow-hidden -my-4 ">
                {window.innerWidth <= 768 ? (
                  <img
                    src="/images/tel2eronde.png"
                    alt="cover"
                    className="absolute w-[90vw] h-[90vw] object-cover"
                  />
                ) : (
                  <img
                    src="/images/tweederonde.png"
                    alt="cover"
                    className="absolute w-full h-full -top-[7%] object-cover"
                  />
                )}
              </div>
            )}
          </div>
          {switchState === "Huidig-" ? (
            null) : (
            <div id="besc" className="w-full flex justify-center text-center -mt-[5vw] xl:-mt-8 graphik xl:pb-6">
              <p className="w-80">
                Mijn interesse in computers is begonnen in 2021 toen mijn oma me vroeg of ik haar HTML website kon onderhouden. Een jaar later heb ik haar huidige website gebouwd met WordPress. <br></br><br></br><a href="https://de-tweede-ronde.nl" target="__blank__" className="underline graphikMedium cursor-alias">De Tweede Ronde</a>
              </p>
            </div>
          )}
        </div>
      </div >
      <div className="lg:flex gap-8 ayer bg-[#161618] w-full">
        <div id="diensten" className="w-full lg:w-1/2 p-8 bg-[#eeeeef] relative pb-10">
          <h2 className="text-[#A09FA6] text-[36px] lg:text-[43px] -mb-3">
            Diensten
          </h2>

          <h3 className="text-[48px] lg:text-[69px]">
            Maken van websites
          </h3>
          <p className="graphik text-[16px]">
            Ik heb ervaring met het bouwen van websites in HTML, CSS, JavaScript, React, Flask, Webflow, Figma en WordPress, maar vind het ook superleuk om uitgedaagd te worden om nieuwe dingen te leren! Ik schrijf ook graag de teksten voor op je site.
          </p>

          <div className="w-[99%] h-[1px] my-6 bg-[#b9b8bd]"></div>

          <h3 className="text-[48px] lg:text-[69px]">
            Plaats in zoekresultaten
          </h3>
          <p className="graphik text-[16px]">
            Wil je dat jouw website bovenaan de zoekresultaten komt te staan? Ik help je graag met het perfectioneren van je website voor de zoekmachines.
          </p>

          <div className="w-[99%] h-[1px] my-6 bg-[#b9b8bd]"></div>


          <h3 className="text-[48px] lg:text-[69px]">
            Merkidentiteit
          </h3>
          <p className="graphik text-[16px]">
            Ben je een beginnend bedrijf en/of heb je nog geen logo of huisstijl? Ik help je graag met het ontwerpen van een logo en huisstijl die bij jouw bedrijf passen.
          </p>

        </div>
        <div id="samenwerkingen" className="w-full lg:w-1/2 text-[#eeeeef] pb-10 lg:pb-0">
          <h2 className=" p-8 pb-0 text-[48px] lg:text-[69px]">
            Grote samenwerkingen
          </h2>
          <p className="graphik text-[16px] p-8 pt-0">
            Ik ben benaderd door het marketingbureau 'RB-Digital'. Daar heb ik een website en logo voor onder andere 'NRG-TEQ' gemaakt. <br></br><br></br>De gedachtegang daarbij was om een uitstraling te creëren van een duurzaam en innovatief bedrijf die paste bij het Duitse moederbedrijf '2G'.
          </p>
          <div className="relative w-[calc(100% - 2rem)] mt-2 -mr-1 ml-8 aspect-video rounded-l-lg overflow-hidden bg-white">
            <video
              src="/vids/NRGTEQ123.mp4"
              loop="loop"
              autoPlay={true}
              playsInline
              muted="muted"
              className="autoplay absolute top-0 left-0 w-full h-full object-contain -ml-1 mt-1"
              poster="/images/NRGTEQ.png"
              onError={(e) => console.error("Video error:", e)}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div id="footer" className="flex justify-between lg:border-t-8 border-[#161618] bg-[#232326] text-[#A09FA6] w-full items-center px-6 min-[400]:px-12 py-8">
        <div className="text-[#EEEEEF] ">Lewan Paalvast © 2024</div>
        <div className="flex gap-4 sm:gap-12"><a target="__blank__" href="mailto:paalvast.lewan@gmail.com" className=" hover:text-[#EEEEEF] ">Mail</a><a target="__blank__" href="https://www.linkedin.com/in/lewan-paalvast/" className=" hover:text-[#EEEEEF] ">Linkedin</a></div>
      </div>
    </div >
  );
};

export const Header = ({ translate, titleComponent }) => {
  return (
    <motion.div
      style={{
        translateY: translate,
      }}
      className="div max-w-5xl xl:max-w-7xl mx-auto text-center"
    >
      {titleComponent}
    </motion.div>
  );
};

export const Card = ({
  rotate,
  scale,
  translate,
  children,
}) => {
  return (
    <motion.div
      style={{
        rotateX: rotate,
        scale,
        boxShadow:
          "0 0 #0000004d, 0 9px 20px #0000004a, 0 37px 37px #00000042, 0 84px 50px #00000026, 0 149px 60px #0000000a, 0 233px 65px #00000003",
      }}
      className="max-w-5xl xl:max-w-7xl -mt-12 mx-auto h-[30rem] md:h-[40rem] xl:h-[48rem] w-full border-4 border-[#6C6C6C] p-2 md:p-6 bg-[#222222] rounded-[30px] shadow-2xl"
    >
      <div className="h-full w-full overflow-hidden rounded-2xl bg-gray-100 dark:bg-zinc-900 md:rounded-2xl md:p-4">
        <div className="bg-white w-full h-full">
          <img
            src={window.innerWidth > 768 ? "/images/eindexamen2.png" : "/images/teleindexamen.png"}
            alt="hero"
            className={`w-full h-full object-cover object-left-top rounded-2xl ${window.innerWidth < 768 ? 'p-2' : ''}`}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
            onError={(e) => console.error("Image failed to load:", e)}
          />
        </div>
        {children}
      </div>
    </motion.div>
  );
};

export default App;